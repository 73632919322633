<template>
  <div class="main">
    <div class="main__tabs">
      <TabsMain @logoutUser="logoutUser" />
      <div class="main__userLogin" @click="toggleMenu">
        {{ userData?.login }}
        <InvisibleCover
          v-if="userLogin"
          @invisibleCoverClickHandler="toggleMenu"
        />
        <ul
          class="main__userLogin-list"
          @mouseleave="toggleMenu"
          :style="{ display: userLogin ? 'block' : 'none' }"
        >
          <li class="main__userLogin-item" @click="logoutUser">Выйти</li>
        </ul>
      </div>
      <div class="main__notifications" @click.stop="openNotifications">
        <div class="notification">
          <span
            class="notification__counter"
            v-if="$store.state.mainData.pushCounter"
            >{{ $store.state.mainData.pushCounter }}</span
          >
          <div class="notification__img" @click.stop="openNotifications">
            <svg
              width="30"
              height="30"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 1.97636C8.18484 1.97636 6.79141 2.66085 5.86645 3.81323C4.95845 4.94448 4.55262 6.46171 4.55182 8.06298L4.51383 10.7174L3.05051 13.4356C2.63806 14.2018 3.19298 15.1307 4.0631 15.1307H6.83556C6.84421 15.1956 6.85583 15.2701 6.87143 15.352C6.92458 15.631 7.02689 16.0122 7.22677 16.4008C7.42761 16.7913 7.73422 17.2029 8.1994 17.516C8.66963 17.8325 9.26555 18.0236 10.0003 18.0236C10.735 18.0236 11.3309 17.8325 11.8012 17.516C12.2663 17.2028 12.5729 16.7913 12.7738 16.4008C12.9736 16.0121 13.076 15.631 13.1291 15.3519C13.1447 15.2701 13.1563 15.1956 13.165 15.1307H15.9369C16.807 15.1307 17.362 14.2018 16.9495 13.4356L15.4862 10.7174L15.4482 8.063C15.4474 6.46172 15.0416 4.94448 14.1336 3.81323C13.2086 2.66084 11.8152 1.97636 10 1.97636ZM11.8478 15.1307H10H8.15275C8.19129 15.3247 8.26078 15.5689 8.38284 15.8062C8.5086 16.0508 8.68283 16.2743 8.92529 16.4375C9.16269 16.5973 9.5012 16.7236 10.0003 16.7236C10.4994 16.7236 10.8379 16.5973 11.0753 16.4375C11.3177 16.2743 11.4919 16.0508 11.6177 15.8062C11.7398 15.5689 11.8092 15.3247 11.8478 15.1307ZM5.85182 8.06771C5.85182 6.66555 6.20844 5.46398 6.88026 4.62697C7.53603 3.80997 8.5417 3.27636 10 3.27636C11.4583 3.27636 12.464 3.80997 13.1198 4.62697C13.7916 5.46398 14.1482 6.66555 14.1482 8.06771H14.1481L14.1483 8.07701L14.1886 10.8949C14.1901 10.9992 14.2167 11.1017 14.2662 11.1937L15.6858 13.8307H10H4.31421L5.73383 11.1937C5.78332 11.1017 5.80993 10.9992 5.81143 10.8949L5.85175 8.07701L5.85182 8.07701V8.06771Z"
                fill="#B7BACB"
              />
            </svg>
          </div>
        </div>
        <ModalNotifications
          v-if="allNotificationsIsShow"
          @closeModalNotifications="closeModalNotifications"
        />
      </div>
    </div>
    <div class="main__page">
      <slot></slot>
    </div>
    <div class="main__push" v-if="pushData.length">
      <PushNotification
        v-for="item in pushData"
        :key="item.id"
        :pushData="item"
      />
    </div>
  </div>
</template>

<script>
import TabsMain from "@/components/TabsMain.vue";
import { useApi } from "@/composition/useApi.js";
import { useStore } from "vuex";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { expFunc } from "@/socket";
import PushNotification from "@/components/UI/PushNotification.vue";
import ModalNotifications from "@/components/modal/ModalNotifications.vue";
import InvisibleCover from "@/components/UI/coversForModals/InvisibleCover.vue";
export default {
  components: {
    TabsMain,
    PushNotification,
    ModalNotifications,
    InvisibleCover,
  },
  setup() {
    const store = useStore();
    const { logout } = useApi();
    const socketState = expFunc();
    const userLogin = ref(false);
    const userData = computed(() => store.state.mainData.userData);
    const allNotificationsIsShow = computed(
      () => store.state.mainData.allNotificationsIsShow,
    );
    const toggleMenu = () => {
      userLogin.value = !userLogin.value;
    };
    const logoutUser = () => {
      logout();
    };
    onMounted(() => {
      // получаем данные и права авторизованного пользователя
      store.dispatch("getUser");
      // делаем коннект, иначе если выйти из системы и зайти, то соединение прерывается
      socketState.connect();
    });
    onBeforeUnmount(() => {
      // перед закрытием шаблона делаем дисконнект
      socketState.disconnect();
      socketState.close();
    });
    const pushData = computed(() => store.state.mainData.pushData);

    const closeModalNotifications = () => {
      // скрываем модалку
      store.commit("setVisibilityNotifications", false);
      // чистим содержимое модального окна
      store.commit("removeNotificationsData");
    };
    const openNotifications = () => {
      store.dispatch("getNotifications", { offset: 0, limit: 10 }).then(() => {
        store.commit("setVisibilityNotifications", true);
      });
    };
    return {
      userLogin,
      userData,
      pushData,
      allNotificationsIsShow,
      logoutUser,
      toggleMenu,
      openNotifications,
      closeModalNotifications,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/normalize/normalize.css";
html {
  line-height: 1.5;
  font-weight: 400;
  font-size: 16px;
}
#app {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-style: normal;
  background-color: #f2f2f2;
  width: 100%;
}

.main {
  max-width: 2200px;
  min-width: 1080px;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  &__tabs {
    padding: 0 25px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__page {
    position: relative;
    width: 100%;
    height: calc(100vh - 50px);
    height: calc(100dvh - 50px);
  }
}
.main__userLogin {
  position: relative;
  font-weight: 700;
  cursor: pointer;
  padding: 10px 12px;
  border-radius: 6px;
  &:hover {
    background-color: #f2f2f2;
  }
  &::after {
    content: "";
    display: inline-block;
    margin-left: -0.02em;
    vertical-align: 0.185em;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  &-list {
    width: 100%;
    position: absolute;
    top: 110%;
    right: 0;
    z-index: 10;
    font-weight: 400;
    background-clip: padding-box;
    min-width: 160px;
    padding: 8px 0;
    color: inherit;
    background-color: white;
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    &:hover {
      background-color: #f2f2f2;
    }
  }
  &-item {
    width: 100%;
    padding: 4px 16px;
    transition: all 0.2s ease-in;
    &:hover {
      background-color: #f2f2f2;
    }
  }
}
.main__push {
  position: fixed;
  top: 10vh;
  right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap-reverse;
  gap: 10px;
  z-index: 3;
  max-height: 80vh;
  max-height: 80dvh;
}
.main__notifications {
  padding: 5px 12px;
  position: relative;
  border-radius: 0.25rem;
  &:hover {
    cursor: pointer;
    background-color: #f2f2f2;
  }
  svg {
    vertical-align: middle;
  }
}
.notification {
  position: relative;
  &__counter {
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 2px;
    width: 12px;
    height: 12px;
    line-height: 12px;
    font-size: 8px;
    border-radius: 50%;
    text-align: center;
    background-color: #ca452e;
    color: #fff;
    animation: pulse 3s 3s both infinite;
  }
}
@media (max-width: 1135px) {
  html {
    font-size: 12px;
    line-height: 1.2;
  }
  .main {
    max-width: 2200px;
    min-width: auto;
  }
}
@media (max-width: 1024px) {
  html {
    font-size: 10px;
  }
  .main {
    &__page {
      height: calc(100vh - 33px);
      height: calc(100dvh - 33px);
    }
    &__notifications {
      .notification__img {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    &__userLogin-list {
      min-width: 100px;
      text-align: cent;
    }
  }
  .notification {
    &__counter {
      font-size: 5px;
      line-height: 10px;
      width: 10px;
      height: 10px;
      top: 0;
      right: 0;
    }
  }
}
@media (max-width: 700px) {
  .main__tabs {
    padding: 0;
  }
}
@media (max-width: 480px) {
  .main__push {
    top: 15vh;
    top: 15dvh;
    right: 10px;
  }
}
</style>
