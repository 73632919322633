import { useApi } from "@/composition/useApi.js";
export default {
  state: {
    allUsers: [],
    isAddedUser: false,
    editUserName: null,
    spinnerIsShow: false,
  },
  mutations: {
    setAllUsers(state, data) {
      state.spinnerIsShow = false;
      state.allUsers = data;
    },
    setAddedUser(state, bool) {
      state.isAddedUser = bool;
    },
    //создание пользователя
    setCreateUser(state, data) {
      state.allUsers.push(data);
    },
    // редактирование и обновление
    setEditUser(state, user) {
      state.editUserName = user;
    },
    setUpdateUser(state, user) {
      state.allUsers = state.allUsers.map(item => {
        if (item.id == user.id) item = user;
        return item;
      });
    },
    //удаление пользователя
    setDeleteUser(state, data) {
      state.allUsers = state.allUsers.filter(user => user.id != data.id);
    },
    //поиск и обновление
    searchUser(state, dataInput) {
      document
        .querySelectorAll(".users .allUsers__tbody .allUsers__tbody-name")
        .forEach(colName => {
          if (colName.innerText.toLowerCase().includes(dataInput.toLowerCase()))
            colName.parentNode.style.display = "table-row";
          else colName.parentNode.style.display = "none";
        });
    },
    searchUserUpdate() {
      document.querySelectorAll(".users .allUsers__tbody tr").forEach(row => {
        row.style.display = "table-row";
      });
    },
  },
  actions: {
    getAllUsers({ commit, state }) {
      const { api } = useApi();
      state.spinnerIsShow = true;
      return api
        .get("/users")
        .then(r => {
          if (r.status == 200) {
            commit("setAllUsers", r.data);
          }
        })
        .catch(e => {
          console.log("error: ", e);
          state.spinnerIsShow = false;
        });
    },
    createUser({ commit }, body) {
      const { api } = useApi();
      return api
        .post("/users", body)
        .then(r => {
          if (r.status == 201) {
            commit("setCreateUser", r.data);
            //закрытие окна
            commit("setAddedUser", false);
          }
        })
        .catch(e => {
          console.log("error: ", e);
        });
    },
    editUser({ commit }, data) {
      const { api } = useApi();
      return api
        .put(`/users/${data.id}`, data.body)
        .then(r => {
          if (r.status == 200) {
            commit("setUpdateUser", r.data);
            //переключение мод окна на "создание"
            commit("setEditUser", null);
            //закрытие окна
            commit("setAddedUser", false);
          }
        })
        .catch(e => {
          console.log("error: ", e);
        });
    },
    deleteUser({ commit }, payload) {
      const { api } = useApi();
      return api
        .delete(`/users/${payload.id}`)
        .then(r => {
          if (r.status == 200) {
            commit("setDeleteUser", payload);
          }
        })
        .catch(e => {
          console.log("error: ", e);
        });
    },
  },
};
