<template>
  <component :is="layout">
    <router-view :key="$route.fullPath" />
  </component>
  <Alert />
</template>
<script>
import { computed } from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import { useRoute } from "vue-router";
import Alert from "./components/UI/AlertComponent.vue";
export default {
  components: {
    DefaultLayout,
    AuthLayout,
    Alert,
  },
  setup() {
    document.title = "Камеры | Альянс-ЮГ";
    const route = useRoute();
    const layout = computed(() => {
      return route.meta.layout || DefaultLayout;
    });
    return { layout };
  },
};
</script>
<style></style>
