<template>
  <div class="createUser">
    <div class="createUser-wrap">
      <div class="createUser__content">
        <div class="createUser__header">
          <div class="createUser__title">
            {{
              $store.state.users.editUserName
                ? "Редактировать"
                : "Создать нового"
            }}
            пользователя
          </div>
          <div class="createUser__content-exit">
            <button class="createUser-btn" @click="closeModal">Закрыть</button>
          </div>
        </div>
        <div class="createUser__content-create">
          <label class="createUser__label" for="input__login"
            >Логин пользователя</label
          >
          <input
            class="createUser__input"
            type="text"
            id="input__login"
            placeholder="Логин"
            v-model="inputLogin"
            @click="$event.target.classList.remove('focus')"
            :disabled="$store.state.users.editUserName"
          />
        </div>
        <div class="createUser__password">
          <div class="createUser__content-create full">
            <label class="createUser__label" for="input_pass">Пароль</label>
            <input
              class="createUser__input"
              type="text"
              id="input_pass"
              @click="$event.target.classList.remove('focus')"
              v-model="inputPassword"
              placeholder="Пароль"
            />
          </div>
          <div class="createUser__generate">
            <button class="createUser-btn" @click="passwordGenerate">
              Сгенерировать
            </button>
          </div>
        </div>
        <div class="createUser__content-create">
          <label class="createUser__label" for="input__tg">Telegram id</label>
          <input
            class="createUser__input"
            type="number"
            min="0"
            step="1"
            id="input__tg"
            placeholder="Tg id"
            v-model.trim="inputTelegram"
          />
        </div>
        <div class="createUser__label">Тип</div>
        <div class="createUser__content-create">
          <select class="createUser__select" v-model="selectedType">
            <option
              v-for="option in selectOptions"
              :value="option.value"
              :key="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </div>
        <div class="createUser__label">Доступные компании</div>
        <div class="createUser__companies" v-if="allCompanies.length">
          <table class="companies">
            <thead class="companies__thead">
              <tr>
                <th class="companies__checkboxTH">
                  <input
                    type="checkbox"
                    class="companies__checkboxAll"
                    ref="checkboxAll"
                    :checked="getAllCheckboxesIsChecked"
                    @click="selectAllCheckbox"
                  />
                </th>
                <th class="companies__countTH">#</th>
                <th class="companies__nameTH">Компания</th>
              </tr>
            </thead>
            <tbody class="companies__tbody">
              <tr
                class="companies__tbody-row"
                v-for="(company, idx) in allCompanies"
                :key="company.id"
                @click="selectCheckbox(company)"
                :style="{
                  background: idx % 2 == 0 ? '#f2f2f2' : '#fff',
                }"
              >
                <td>
                  <input
                    type="checkbox"
                    class="companies__checkbox"
                    :checked="company.checked"
                  />
                </td>
                <td>{{ company.id }}</td>
                <td>
                  <div>{{ company.name }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="createUser__content-edit"
          v-if="$store.state.users.editUserName"
        >
          <button class="createUser-btn" @click="editUser">Изменить</button>
        </div>
        <div class="createUser__content-added" v-else>
          <button class="createUser-btn" @click="addedUser">Создать</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
export default {
  name: "createUser",
  setup() {
    const store = useStore();
    const inputLogin = ref(""); // инпут логина
    const inputTelegram = ref(); // инпут tg
    const inputPassword = ref(""); // инпут пароля
    const selectedType = ref("CUSTOMER"); // выбранный тип пользователя
    const selectOptions = ref([
      // все типы пользователей
      { text: "Администратор", value: "ADMIN" },
      { text: "Оператор", value: "OPERATOR" },
      { text: "Обычный пользователь", value: "CUSTOMER" },
    ]);
    const allCompanies = computed(() => store.state.companies.allCompanies); // получение списка всех компаний
    const checkboxAll = ref(false); // состояние чекбокса "Выбрать все"
    // computed cв-во которое убирает/ добавляет галочку "Выбрать все"
    const getAllCheckboxesIsChecked = computed(
      () =>
        !store.state.companies.allCompanies.find(company => !company.checked),
    );
    // computed cв-во возвращает массив ids выбранных чекбоксов
    const getCheckedCheckboxesIds = computed(() =>
      store.state.companies.allCompanies
        .filter(company => company.checked)
        .map(item => item.id),
    );

    const closeModal = () => {
      // закрытие модалки
      store.commit("setAddedUser", false);
      // убираем чекбоксы
      store.commit("setSelectAllCheckboxes", false);
      // если открывали мод окно для редактирования(смена на создание)
      if (store.state.users.editUserName) store.commit("setEditUser", null);
    };
    const addedUser = () => {
      if (!inputLogin.value || !inputPassword.value) {
        // показ alert если поля пусты
        store.commit("setAlertText", {
          text: ["Сначала заполните все поля!"],
          type: "danger",
        });
        // закрашивание полей
        if (!inputLogin.value)
          document.querySelector("#input__login").classList.add("focus");
        if (!inputPassword.value)
          document.querySelector("#input_pass").classList.add("focus");
      } else {
        // submit отправка данных на сервер
        store.dispatch("createUser", {
          login: inputLogin.value,
          password: inputPassword.value,
          tgId: inputTelegram.value,
          role: selectedType.value,
          companyIds: getCheckedCheckboxesIds.value,
        });
      }
    };

    const editUser = () => {
      let data = {
        id: store.state.users.editUserName.id,
        body: inputPassword.value
          ? {
              password: inputPassword.value,
              role: selectedType.value,
              companyIds: getCheckedCheckboxesIds.value,
            }
          : {
              role: selectedType.value,
              companyIds: getCheckedCheckboxesIds.value,
            },
      };
      if (inputTelegram.value) data.body.tgId = inputTelegram.value;
      store.dispatch("editUser", data);
    };
    onMounted(() => {
      // если окно открыто для редактирования, подставляем данные
      if (store.state.users.editUserName) {
        inputLogin.value = store.state.users.editUserName.login;
        inputTelegram.value = store.state.users.editUserName.tgId;
        inputPassword.value = "";
        selectedType.value = store.state.users.editUserName.role;
        // отмечаем чекбоксы
        store.commit(
          "setSomeCheckboxes",
          store.state.users.editUserName.companyIds,
        );
      }
    });
    const selectCheckbox = company => {
      // выбор и снятие чекбокса
      store.commit("setToggleCheckbox", {
        id: company.id,
        isChecked: !company.checked,
      });
    };
    const selectAllCheckbox = () => {
      if (checkboxAll.value.checked) {
        store.commit("setSelectAllCheckboxes", true);
      } else store.commit("setSelectAllCheckboxes", false);
    };
    const passwordGenerate = () => {
      // генерация пароля (16 символов)
      inputPassword.value =
        Math.random().toString(36).slice(2, 10) +
        Math.random().toString(36).toUpperCase().slice(2, 10);
    };
    return {
      closeModal,
      addedUser,
      editUser,
      selectCheckbox,
      selectAllCheckbox,
      passwordGenerate,
      getAllCheckboxesIsChecked,
      checkboxAll,
      allCompanies,
      inputLogin,
      inputTelegram,
      inputPassword,
      selectedType,
      selectOptions,
      getCheckedCheckboxesIds,
    };
  },
};
</script>

<style lang="scss" scoped>
.createUser {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  transition: opacity 200ms ease-in;
  margin: 0;
  padding: 0;
  pointer-events: auto;
  opacity: 1;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__title {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
      Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    line-height: 1.5;
    color: #9c9c9c;
    font-weight: 700;
    font-size: 1.1rem;
    margin-bottom: 15px;
  }
  &-wrap {
    min-width: 650px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    padding: 30px;
    max-height: 90vh;
    max-height: 90dvh;
    overflow-y: auto;
  }
  &__password {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    column-gap: 5px;
    padding-bottom: 15px;
    .createUser__content-create {
      margin: 0;
    }
  }
  &__content {
    width: 100%;
    &-create {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      &.full {
        flex: 1;
      }
    }
    &-added,
    &-exit,
    &-edit {
      text-align: end;
    }
    &-exit {
      margin-bottom: 15px;
    }
    &-added,
    &-edit {
      margin-top: 15px;
    }
  }
  &__companies {
    height: 30vh;
    height: 30dvh;
    overflow-y: auto;
  }
  &__label {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
      Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-size: 1.1rem;
    margin-bottom: 5px;
  }
  &__input,
  &__select {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 9px 15px;
    padding-right: 20px;
    outline: none;
    &.focus {
      border-color: #e31e24;
      box-shadow: 0 0 0 0.25rem rgb(227 30 36 / 25%);
    }
    &:disabled {
      cursor: not-allowed;
      color: #ced4da;
    }
    &:focus {
      border-color: #e31e24;
      box-shadow: 0 0 0 0.25rem rgb(227 30 36 / 25%);
    }
  }
  &-btn {
    color: #fff;
    background-color: #e31e24;
    border-color: #e31e24;
    border: 1px solid transparent;
    padding: 9px 12px;
    text-align: center;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover {
      color: #fff;
      background-color: #b02a37;
      border-color: #b02a37;
    }
  }
}
.companies {
  width: 100%;
  height: auto;
  border-collapse: collapse;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  &__thead {
    background: #fff;
    position: sticky;
    top: -0.3px;
    left: 0;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: #000;
    }
    & tr {
      & th {
        text-align: center;
      }
    }
  }
  &__tbody tr {
    border-bottom: 1px solid #dee2e6;
    cursor: pointer;
    &:hover {
      background-color: #b1afaf !important;
    }
  }
  & th,
  & td {
    padding: 0.5rem 0.5rem;
    text-align: center;
  }
  &__checkboxAll,
  &__checkbox {
    appearance: none;
    margin: 0 auto;
    width: 1rem;
    height: 1rem;
    border: 1px solid #00000040;
    border-radius: 0.25em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    &:checked {
      background-color: #e31e24;
    }
  }
  &__checkboxTH,
  &__countTH {
    width: 62px;
  }
  &__nameTH {
    width: 65%;
  }
}
@media (max-width: 1024px) {
  .createUser {
    &-wrap {
      min-width: 500px;
      padding: 20px;
    }
    &-btn {
      padding: 5px 8px;
    }
    &__input,
    &__select {
      padding: 5px 8px;
    }
  }
  .companies {
    &__nameTH {
      width: 85%;
    }
  }
}
@media (max-width: 768px) {
  .createUser {
    &-wrap {
      min-width: 80vw;
    }
  }
}
@media (max-width: 480px) {
  .createUser {
    &-wrap {
      min-width: auto;
      width: 98vw;
      padding: 20px 10px;
    }
    &-btn,
    &__input,
    &__select {
      padding: 8px;
    }
  }
  .companies {
    &__checkbox,
    &__checkboxAll {
      width: 2rem;
      height: 2rem;
    }
  }
}
</style>
