<template>
  <table class="allCompanies">
    <thead class="allCompanies__thead">
      <tr>
        <th class="allCompanies__thead-number">#</th>
        <th>Название</th>
        <th>Создан</th>
        <th>Изменен</th>
        <th class="allCompanies__thead-actions">Действия</th>
      </tr>
    </thead>
    <tbody class="allCompanies__tbody">
      <tr
        v-for="(company, idx) in $store.state.companies.allCompanies"
        :key="company.id"
        :style="{
          background: idx % 2 == 0 ? '#f2f2f2' : '#fff',
        }"
      >
        <td>{{ company.id }}</td>
        <td class="allCompanies__tbody-name">{{ company.name }}</td>
        <td>{{ new Date(company.createdAt).toLocaleString() }}</td>
        <td>{{ new Date(company.updatedAt).toLocaleString() }}</td>
        <td>
          <div class="allCompanies__actions">
            <div
              class="allCompanies__actions-btn allCompanies__actions-edit"
              @click="editCompany(company)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-pencil"
                viewBox="0 0 16 16"
              >
                <path
                  d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                />
              </svg>
            </div>
            <div
              class="allCompanies__actions-btn allCompanies__actions-delete"
              @click="deleteCompany(company)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-trash-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"
                />
              </svg>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "allCompanies",
  setup() {
    const store = useStore();
    const editCompany = company => {
      // открываем мод окно
      store.commit("setAddedCompany", true);
      // передаем имя для редактирования
      store.commit("setEditCompany", company);
    };

    const deleteCompany = company => {
      if (confirm(`Удалить компанию ${company.name}`))
        store.dispatch("deleteCompany", company);
    };
    return {
      editCompany,
      deleteCompany,
    };
  },
};
</script>

<style lang="scss" scoped>
.allCompanies {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #dee2e6;
  border-top: 0;
  position: relative;
  & tr {
    border-bottom: 1px solid #dee2e6;
  }
  & th,
  & td {
    padding: 0.5rem 0.5rem;
    text-align: center;
  }
  &__thead {
    text-align: center;
    font-weight: 700;
    position: sticky;
    background-color: #fff;
    top: 0;
    left: 0;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #dee2e6;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #dee2e6;
    }
    &-actions,
    &-number {
      width: 82px;
    }
  }
  &__actions {
    width: 82px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    &-btn {
      width: 34px;
      height: 34px;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      margin: 0 auto;
      padding: 0.35rem 0.51rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-edit {
      color: #000;
      border-color: #ffc107;
      background: #ffc107;
      text-align: center;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &:hover {
        background-color: #e9b109;
        border-color: #e9b109;
      }
    }
    &-delete {
      color: #fff;
      border-color: #e31e24;
      background: #e31e24;
      text-align: center;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &:hover {
        border-color: #b02a37;
        background: #b02a37;
      }
    }
  }
}
@media (max-width: 1024px) {
  .allCompanies {
    &__actions {
      column-gap: 5px;
      width: auto;
      &-btn {
        width: 24px;
        height: 24px;
        padding: 0.5rem;
        margin: 0;
      }
    }
    &__thead {
      &-actions {
        width: 80px;
      }
      &-number {
        width: 50px;
      }
    }
  }
}
@media (max-width: 480px) {
  .allCompanies {
    &__thead {
      &-number {
        width: 0;
      }
    }
  }
}
</style>
