<template>
  <div class="createAccount">
    <div class="createAccount-wrap">
      <div class="createAccount__content">
        <div class="createAccount__header">
          <div class="createAccount__title">
            {{
              $store.state.billing.editAccount ? "Редактирование" : "Добавление"
            }}
            счёта
          </div>
          <div class="createAccount__content-exit">
            <button class="createAccount-btn" @click="closeModal">
              Закрыть
            </button>
          </div>
        </div>
        <div class="createAccount__label">Компания</div>
        <div class="createAccount__content-create">
          <select
            class="createAccount__select"
            id="input__companies"
            v-model="selectedType"
            @click="$event.target.classList.remove('focus')"
            :disabled="$store.state.billing.editAccount"
          >
            <option value="none" disabled selected hidden>
              Нет новых компаний для добавления счёта
            </option>
            <option
              v-for="option in selectOptions"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
        <div class="createAccount__content-create">
          <label class="createAccount__label" for="createAccount-create"
            >Баланс</label
          >
          <input
            class="createAccount__input"
            type="number"
            step="100"
            id="input__balance"
            placeholder="5000"
            v-model="inputBalance"
            :disabled="$store.state.billing.editAccount"
            @click="$event.target.classList.remove('focus')"
          />
        </div>
        <div class="createAccount__content-create">
          <div class="blockingCompany">
            <div class="blockingCompany__title">Блокировать компанию</div>
            <input
              type="checkbox"
              class="blockingCompany__checkbox"
              v-model="blockCompany"
            />
          </div>
        </div>
        <div class="createAccount__content-create">
          <label class="createAccount__label" for="createAccount-create"
            >Порог блокировки</label
          >
          <input
            class="createAccount__input"
            type="number"
            min="0"
            step="1"
            id="input__threshold"
            placeholder="0"
            @click="$event.target.classList.remove('focus')"
            v-model="inputBlockingThreshold"
          />
        </div>
        <div class="createAccount__label">Тариф по типам</div>
        <div class="createAccount__tariff">
          <table
            class="tariff"
            @click="$event.target.closest('.tariff').classList.remove('focus')"
          >
            <thead class="tariff__thead">
              <tr>
                <th class="tariff__objType">Тип объекта</th>
                <th class="tariff__objPrice">Стоимость в мес.</th>
              </tr>
            </thead>
            <tbody class="tariff__tbody">
              <tr
                class="tariff__tbody-row"
                v-for="(tariffType, idx) in inputTariffTypes"
                :key="idx"
              >
                <td>{{ tariffType.name }}</td>
                <td>
                  <input
                    class="createAccount__input"
                    type="number"
                    min="0"
                    step="100"
                    placeholder="200"
                    v-model="tariffType.value"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="createAccount__content-edit"
          v-if="$store.state.billing.editAccount"
        >
          <button class="createAccount-btn" @click="editAccount">
            Изменить
          </button>
        </div>
        <div class="createAccount__content-added" v-else>
          <button class="createAccount-btn" @click="addAccount">
            Добавить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
export default {
  name: "createAccount",
  setup() {
    const store = useStore();
    const selectOptions = computed(() => {
      if (store.state.billing.editAccount) {
        // если модалка открыта для редактирования, фильтровать не нужно
        return store.state.companies.allCompanies;
      } else {
        // получаем массив id
        const accountsIdArr = store.state.billing.allAccounts.map(
          account => account.companyId,
        );
        // фильтруем компнии по id
        const res = store.state.companies.allCompanies.filter(
          company => !accountsIdArr.includes(company.id),
        );
        return res;
      }
    });
    const selectedType = ref(
      selectOptions.value.length ? selectOptions.value[0].id : "none",
    ); // компания по умолчанию
    const inputBalance = ref(null); // инпут баланса
    const inputBlockingThreshold = ref(null); // инпут порог блокировки
    const blockCompany = ref(true); // инпут блокировать компанию
    const inputTariffTypes = ref(
      JSON.parse(JSON.stringify(store.state.billing.tariffTypes)),
    ); // инпуты тарифа по типам
    inputTariffTypes.value.forEach(item => (item.value = "")); // формируем массив обьектов со значениями

    const closeModal = () => {
      // закрытие модалки
      store.commit("setAddedAccount", false);
      // если открывали мод окно для редактирования(смена на создание)
      if (store.state.billing.editAccount) store.commit("setEditAccount", null);
    };
    const editAccount = () => {
      let data = {
        id: store.state.billing.editAccount.id,
        body: {
          companyId: selectedType.value,
          rates: getTariffByType(),
          balance: inputBalance.value,
          blockCompany: blockCompany.value,
          blockingThreshold: inputBlockingThreshold.value,
        },
      };
      store.dispatch("editAccount", data);
    };
    function getTariffByType() {
      // формируем массив TariffByType для отправки на сервер
      let res = [];
      inputTariffTypes.value.forEach(tariffType => {
        if (tariffType.value) {
          res.push({ objectType: tariffType.type, price: tariffType.value });
        }
      });

      return res;
    }
    const addAccount = () => {
      if (
        selectedType.value == "none" ||
        !inputBalance.value ||
        !inputBlockingThreshold.value ||
        !getTariffByType().length
      ) {
        // показ alert если поля пусты
        store.commit("setAlertText", {
          text: ["Сначала заполните все поля!"],
          type: "danger",
        });
        // закрашивание полей
        if (selectedType.value == "none")
          document.querySelector("#input__companies").classList.add("focus");
        if (!inputBalance.value)
          document.querySelector("#input__balance").classList.add("focus");
        if (!inputBalance.value)
          document.querySelector("#input__threshold").classList.add("focus");
        if (!getTariffByType().length)
          document.querySelector(".tariff").classList.add("focus");
      } else {
        // submit отправка данных на сервер
        store.dispatch("createAccount", {
          companyId: selectedType.value,
          rates: getTariffByType(),
          balance: inputBalance.value,
          blockCompany: blockCompany.value,
          blockingThreshold: inputBlockingThreshold.value,
        });
      }
    };
    onMounted(() => {
      // если окно открыто для редактирования, подставляем данные
      const editingAccount = store.state.billing.editAccount;
      if (store.state.billing.editAccount) {
        selectedType.value = editingAccount.company.id;
        inputBalance.value = editingAccount.balance;
        blockCompany.value = editingAccount.blockCompany;
        inputBlockingThreshold.value = editingAccount.blockingThreshold;
        editingAccount.rates.forEach(rate => {
          inputTariffTypes.value.forEach(tariffType => {
            if (rate.objectType == tariffType.type)
              tariffType.value = rate.price;
          });
        });
      }
    });
    return {
      closeModal,
      addAccount,
      editAccount,
      inputBalance,
      inputBlockingThreshold,
      blockCompany,
      selectedType,
      selectOptions,
      inputTariffTypes,
    };
  },
};
</script>

<style lang="scss" scoped>
.createAccount {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  transition: opacity 200ms ease-in;
  margin: 0;
  padding: 0;
  pointer-events: auto;
  opacity: 1;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__title {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
      Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    line-height: 1.5;
    color: #9c9c9c;
    font-weight: 700;
    font-size: 1.1rem;
    margin-bottom: 15px;
  }
  &-wrap {
    min-width: 650px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    padding: 30px;
    max-height: 90vh;
    max-height: 90dvh;
    overflow-y: hidden;
  }
  &__content {
    width: 100%;
    &-create {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
    }
    &-added,
    &-exit,
    &-edit {
      text-align: end;
    }
    &-exit {
      margin-bottom: 15px;
    }
    &-added,
    &-edit {
      margin-top: 15px;
    }
  }
  &__tariff {
    height: 30vh;
    height: 30dvh;
    overflow-y: auto;
    position: relative;
  }
  &__label {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
      Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-size: 1.1rem;
    margin-bottom: 5px;
  }
  &__input,
  &__select {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 9px 15px;
    padding-right: 20px;
    outline: none;
    &.focus {
      border-color: #e31e24;
      box-shadow: inset 0px 0px 10px rgb(227 30 36 / 25%);
    }
    &:disabled {
      cursor: not-allowed;
      color: #ced4da;
    }
  }
  &-btn {
    color: #fff;
    background-color: #e31e24;
    border-color: #e31e24;
    border: 1px solid transparent;
    padding: 9px 12px;
    text-align: center;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover {
      color: #fff;
      background-color: #b02a37;
      border-color: #b02a37;
    }
  }
}
.blockingCompany {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  &__title {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
      Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-size: 1.1rem;
  }
  &__checkbox {
    appearance: none;
    width: 1rem;
    height: 1rem;
    border: 1px solid #00000040;
    border-radius: 0.25em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    &:checked {
      background-color: #e31e24;
    }
  }
}
.tariff {
  width: 100%;
  height: auto;
  border-collapse: collapse;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  &.focus {
    border: 2px solid #e31e24;
    border-bottom: 3px solid #e31e24;
    box-shadow: inset 1px 10px 10px rgb(227 30 36 / 25%);
    display: block;
    z-index: 1;
    .tariff__thead {
      z-index: -1;
    }
    .tariff__tbody {
      position: relative;
      z-index: -2;
    }
  }
  &__thead {
    background: #fff;
    position: sticky;
    top: -0.3px;
    left: 0;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: #000;
    }
    & tr {
      & th {
        text-align: center;
      }
    }
  }
  &__tbody {
    tr:nth-child(odd) {
      background: #f2f2f2;
    }
    tr:nth-child(even) {
      background: #fff;
    }
    tr {
      border-bottom: 1px solid #dee2e6;
      cursor: pointer;
      &:hover {
        background-color: #b1afaf !important;
      }
    }
  }
  & th,
  & td {
    padding: 0.5rem 0.5rem;
    text-align: center;
  }
  &__checkboxAll,
  &__checkbox {
    appearance: none;
    margin: 0 auto;
    width: 1rem;
    height: 1rem;
    border: 1px solid #00000040;
    border-radius: 0.25em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    &:checked {
      background-color: #e31e24;
    }
  }
  &__objPrice {
    width: 30%;
  }
}
@media (max-width: 1024px) {
  .createAccount {
    &-wrap {
      min-width: 50vw;
      padding: 20px;
    }
    &-btn,
    &__select,
    &__input {
      padding: 5px 8px;
    }
  }
}
@media (max-width: 768px) {
  .createAccount {
    &-wrap {
      min-width: 80vw;
    }
  }
}
@media (max-width: 480px) {
  .createAccount {
    &-wrap {
      min-width: auto;
      width: 98vw;
    }
    &-btn,
    &__select,
    &__input {
      padding: 8px;
    }
  }
  .blockingCompany {
    &__checkbox {
      width: 2rem;
      height: 2rem;
    }
  }
}
</style>
