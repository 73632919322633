<template>
  <span class="grayCover" @click.stop="$emit('grayCoverClickHandler')" />
</template>

<script>
export default {
  emits: ["grayCoverClickHandler"],
};
</script>

<style lang="scss" scoped>
.grayCover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  z-index: 9;
  background-color: rgb(0, 0, 0, 0.5);
  overflow: hidden;
}
</style>
