<template>
  <div class="replenishBalance">
    <div class="replenishBalance-wrap">
      <div class="replenishBalance__content">
        <div class="replenishBalance__header">
          <div class="replenishBalance__title">
            Счёт:
            <span>{{
              $store.state.billing.currentAccountForReplenish.company.name
            }}</span>
          </div>
          <div class="replenishBalance__content-exit">
            <button class="replenishBalance-btn" @click="closeModal">
              Закрыть
            </button>
          </div>
        </div>
        <div class="replenishBalance__text">Пополнить баланс</div>
        <div class="replenishBalance__content-create">
          <label class="replenishBalance__label" for="replenishBalance-create"
            >Сумма платежа:</label
          >
          <input
            class="replenishBalance__input"
            type="number"
            step="100"
            id="input__payment"
            placeholder="Введите сумму платежа"
            v-model="inputPayment"
            @click="$event.target.classList.remove('focus')"
          />
        </div>
        <div class="replenishBalance__content-create">
          <label class="replenishBalance__label" for="replenishBalance-create"
            >Описание</label
          >
          <input
            class="replenishBalance__input"
            type="text"
            id="input__desc"
            placeholder="Введите описание"
            @click="$event.target.classList.remove('focus')"
            v-model="inputDescription"
          />
        </div>
        <div class="replenishBalance__content-added">
          <button class="replenishBalance-btn" @click="topUpAccount">
            Пополнить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
export default {
  name: "replenishBalance",
  setup() {
    const store = useStore();
    const inputPayment = ref(null);
    const inputDescription = ref("");
    const closeModal = () => {
      // закрытие модалки
      store.commit("setReplenishBalanceModal", false);
      // чистим данные
      if (store.state.billing.currentAccountForReplenish)
        store.commit("setCurrentAccountForReplenish", null);
    };
    const topUpAccount = () => {
      if (!inputPayment.value || !inputDescription.value) {
        // показ alert если поля пусты
        store.commit("setAlertText", {
          text: ["Сначала заполните все поля!"],
          type: "danger",
        });
        // закрашивание полей
        if (!inputPayment.value)
          document.querySelector("#input__payment").classList.add("focus");
        if (!inputDescription.value)
          document.querySelector("#input__desc").classList.add("focus");
      } else {
        let data = {
          id: store.state.billing.currentAccountForReplenish.id,
          body: {
            sum: inputPayment.value,
            description: inputDescription.value,
          },
        };
        store.dispatch("topUpAccount", data);
      }
    };
    return {
      closeModal,
      topUpAccount,
      inputPayment,
      inputDescription,
    };
  },
};
</script>

<style lang="scss" scoped>
.replenishBalance {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  transition: opacity 200ms ease-in;
  margin: 0;
  padding: 0;
  pointer-events: auto;
  opacity: 1;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__title {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
      Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    margin-bottom: 15px;
    span {
      line-height: 1.5;
      color: #9c9c9c;
      font-weight: 700;
      font-size: 1.1rem;
    }
  }
  &__text {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
      Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    margin: 20px 0;
    text-align: center;
    line-height: 1.5;
    color: #9c9c9c;
    font-weight: 700;
    font-size: 1rem;
  }
  &-wrap {
    min-width: 650px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    padding: 30px;
    max-height: 90vh;
    max-height: 90dvh;
    overflow-y: hidden;
  }
  &__content {
    width: 100%;
    &-create {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
    }
    &-added,
    &-exit {
      text-align: end;
    }
    &-exit {
      margin-bottom: 15px;
    }
    &-added {
      margin-top: 15px;
    }
  }
  &__label {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
      Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-size: 1.1rem;
    margin-bottom: 5px;
  }
  &__input {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 9px 15px;
    padding-right: 20px;
    outline: none;
    &.focus {
      border-color: #e31e24;
      box-shadow: inset 0px 0px 10px rgb(227 30 36 / 25%);
    }
    &:disabled {
      cursor: not-allowed;
      color: #ced4da;
    }
  }
  &-btn {
    color: #fff;
    background-color: #e31e24;
    border-color: #e31e24;
    border: 1px solid transparent;
    padding: 9px 12px;
    text-align: center;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover {
      color: #fff;
      background-color: #b02a37;
      border-color: #b02a37;
    }
  }
}
@media (max-width: 1024px) {
  .replenishBalance {
    &__title {
      margin-bottom: 5px;
    }
    &__content-exit {
      margin-bottom: 0;
    }
    &__text {
      margin: 10px 0;
    }
    &-wrap {
      padding: 20px;
      min-width: 50vw;
    }
    &-btn,
    &__input {
      padding: 5px 8px;
    }
  }
}
@media (max-width: 1024px) {
  .replenishBalance {
    &-wrap {
      min-width: 70vw;
    }
  }
}
@media (max-width: 480px) {
  .replenishBalance {
    &-wrap {
      min-width: auto;
      width: 98vw;
    }
    &__text {
      margin: 30px 0 15px 0;
      font-size: 1.3rem;
    }
    &-btn,
    &__input {
      padding: 8px;
    }
  }
}
</style>
