<template>
  <table class="allAccounts" v-if="allAccounts.length">
    <thead class="allAccounts__thead">
      <tr>
        <th class="allAccounts__thead-ico"></th>
        <th>Компания</th>
        <th class="allAccounts__thead-balance">Баланс</th>
        <th class="allAccounts__thead-actions">Действия</th>
      </tr>
    </thead>
    <tbody class="allAccounts__tbody">
      <tr
        v-for="(account, idx) in allAccounts"
        :key="account.id"
        :style="{ background: idx % 2 == 0 ? '#f2f2f2' : '#fff' }"
      >
        <td class="allAccounts__tbody-status">
          <svg
            v-if="account.isBlocked"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#E31E24"
            class="bi bi-lock-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"
            />
          </svg>
        </td>
        <td class="allAccounts__tbody-name">{{ account.company.name }}</td>
        <td :style="{ color: account.balance < 0 ? 'red' : '#000' }">
          {{ account.balance }}
        </td>
        <td>
          <div class="allAccounts__actions">
            <div
              class="allAccounts__actions-btn allAccounts__actions-replenish"
              @click="replenishBalance(account)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-cash-stack"
                viewBox="0 0 16 16"
              >
                <path
                  d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                />
                <path
                  d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"
                />
              </svg>
            </div>
            <div
              class="allAccounts__actions-btn allAccounts__actions-edit"
              @click="editAccount(account)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-pencil"
                viewBox="0 0 16 16"
              >
                <path
                  d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                />
              </svg>
            </div>
            <div
              class="allAccounts__actions-btn allAccounts__actions-delete"
              @click="deleteAccount(account)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-trash-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"
                />
              </svg>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "allAccounts",
  setup() {
    const store = useStore();
    const allAccounts = computed(() => store.state.billing.allAccounts);
    const editAccount = account => {
      //сначала получаем список компаний и все типы для тарифа
      Promise.all([
        store.dispatch("getAllCompanies"),
        store.dispatch("getTariffTypes"),
      ]).then(() => {
        // открываем мод окно
        store.commit("setAddedAccount", true);
        // передаем имя для редактирования
        store.commit("setEditAccount", account);
      });
    };
    const deleteAccount = account => {
      if (confirm(`Удалить счёт ${account.company.name}?`))
        store.dispatch("deleteAccount", account);
    };
    const replenishBalance = account => {
      // открываем мод окно
      store.commit("setCurrentAccountForReplenish", account);
      store.commit("setReplenishBalanceModal", true);
    };
    return {
      allAccounts,
      editAccount,
      replenishBalance,
      deleteAccount,
    };
  },
};
</script>

<style lang="scss" scoped>
.allAccounts {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #dee2e6;
  border-top: 0;
  position: relative;
  & tr {
    border-bottom: 1px solid #dee2e6;
  }
  & th,
  & td {
    padding: 0.5rem 0.5rem;
    text-align: center;
  }
  &__thead {
    text-align: center;
    font-weight: 700;
    position: sticky;
    background-color: #fff;
    top: 0;
    left: 0;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #dee2e6;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #dee2e6;
    }
    &-ico {
      width: 52px;
    }
    &-balance {
      width: fit-content;
    }
    &-actions {
      width: 120px;
    }
  }
  &__tbody {
    &-name {
      text-align: left !important;
    }
  }
  &__actions {
    margin: 0 auto;
    display: flex;
    column-gap: 5px;
    width: fit-content;
    justify-content: center;
    &-btn {
      width: 34px;
      height: 34px;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      margin: 0 auto;
      padding: 0.35rem 0.51rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-edit {
      color: #000;
      border-color: #ffc107;
      background: #ffc107;
      text-align: center;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &:hover {
        background-color: #e9b109;
        border-color: #e9b109;
      }
    }
    &-delete {
      color: #fff;
      border-color: #e31e24;
      background: #e31e24;
      text-align: center;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &:hover {
        border-color: #b02a37;
        background: #b02a37;
      }
    }
    &-replenish {
      color: #fff;
      border-color: #888787;
      background: #888787;
      text-align: center;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &:hover {
        border-color: #5d5c5c;
        background: #5d5c5c;
        position: relative;
        &::after {
          content: "Пополнить баланс счета";
          white-space: nowrap;
          background-color: #414040;
          border: 1px solid #fff;
          position: absolute;
          font-size: 0.7em;
          top: 130%;
          right: 0;
          padding: 5px;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .allAccounts {
    &__thead {
      &-actions {
        width: 80px;
      }
    }
    &__tbody {
      &-status {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
    &__actions {
      &-btn {
        width: 24px;
        height: 24px;
        padding: 0.5rem;
      }
    }
  }
}
@media (max-width: 480px) {
  .allAccounts {
    &__thead {
      &-ico {
        width: 20px;
      }
    }
  }
}
</style>
