import { reactive } from "vue";
import { io } from "socket.io-client";
import config from "/config.json";
import store from "./store";

export const state = reactive({
  connected: false,
});

const URL = config.API_URL;

export const expFunc = () => {
  const socket = io(URL, {
    extraHeaders: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
    autoConnect: true,
  });

  socket.on("connect", () => {
    state.connected = true;
  });

  socket.on("disconnect", () => {
    state.connected = false;
  });

  socket.on("state", data => {
    // даем знать что получено новое уведомление если не открыто окно уведомлений
    if (!store.state.mainData.allNotificationsIsShow) {
      store.commit("setPushCounter");
    }
    let ending = ["", "и"]; // окончания для уведомлений
    if (data.groups.length == 1) {
      ending[0] = "";
      ending[1] = "";
    } else if ([2, 3, 4].includes(data.groups.length)) {
      ending[0] = "а";
    } else {
      ending[0] = "ов";
    }
    // делаем либо зеленое уведомление либо красное
    if (data.state == "OFFLINE") {
      store.dispatch("setPushNotification", {
        id: data.id,
        text: [
          `${data.groups.length} объект${ending[0]} из компании ${data.name} стал${ending[1]} недоступным${ending[1]}.`,
        ],
        type: "danger",
      });
    } else {
      store.dispatch("setPushNotification", {
        id: data.id,
        text: [
          `${data.groups.length} объект${ending[0]} из компании ${data.name} снова в сети.`,
        ],
        type: "success",
      });
    }
    // обновляем статус камеры
    store.commit("setObjectStatus", data);
    // обновляем статус групп камер
    store.commit("setCamerasGroupStatus");
  });
  return socket;
};
