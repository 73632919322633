<template>
  <div class="spinner-border"></div>
</template>
<style lang="scss" scoped>
.spinner-border {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  color: #e31e24;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-left-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
  transform: translate(-50%, -50%);
}
@keyframes spinner-border {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
