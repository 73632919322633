<template>
  <InvisibleCover
    @invisibleCoverClickHandler="$emit('closeModalNotifications')"
  />
  <div class="notificationsModal" @click.stop>
    <div
      class="notificationsModal__closeBtn"
      @click.stop="$emit('closeModalNotifications')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill="white"
        stroke="#9c9c9c"
        stroke-opacity="0.8"
        class="bi bi-x"
        viewBox="0 0 16 16"
      >
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
        />
      </svg>
    </div>
    <ul class="notificationsModal__list" v-if="allNotifications.length">
      <li
        class="notificationsModal__item"
        v-for="notification in allNotifications"
        :key="notification.id"
      >
        <div
          class="notificationsModal__ico"
          v-if="notification.details.state == 'ONLINE'"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="#54a754"
            class="bi bi-check-circle-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
            />
          </svg>
        </div>
        <div class="notificationsModal__ico" v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="#ca452e"
            class="bi bi-x-circle-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
            />
          </svg>
        </div>
        <div class="notificationsModal__wrap">
          <div class="notificationsModal__time">
            {{
              "Время: " +
              new Date(notification.createdAt).toLocaleString("ru-RU")
            }}г.
          </div>
          <div class="notificationsModal__corp">
            {{ "Организация: " + notification.details.name }}
          </div>
          <div
            class="notificationsModal__desc"
            v-for="group in notification.details.groups"
            :key="group.id"
          >
            {{ "Обьект: " + group.name }}
          </div>
        </div>
      </li>
      <li
        class="notificationsModal__more"
        v-if="$store.state.mainData.showMoreBtn"
        @click.stop="showMore"
      >
        Показать ещё
      </li>
    </ul>
    <div class="notificationsModal__empty" v-else>Уведомлений нет</div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import InvisibleCover from "@/components/UI/coversForModals/InvisibleCover.vue";
export default {
  emits: ["closeModalNotifications"],
  components: { InvisibleCover },
  setup() {
    const store = useStore();
    const allNotifications = computed(
      () => store.state.mainData.allNotifications,
    );
    let limitIdx = 10;
    let offsetIdx = 0;
    const showMore = () => {
      offsetIdx += 10;
      store.dispatch("getNotifications", {
        offset: offsetIdx,
        limit: limitIdx,
      });
    };
    return {
      allNotifications,
      showMore,
    };
  },
};
</script>

<style lang="scss" scoped>
.notificationsModal {
  padding: 20px 0;
  position: absolute;
  top: 110%;
  right: 0;
  cursor: default;
  z-index: 10;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  background-color: white;
  &__list {
    width: 100%;
    font-weight: 400;
    background-clip: padding-box;
    min-width: 160px;
    padding: 8px 0;
    color: inherit;
    text-align: left;
    height: 60vh;
    height: 60dvh;
    overflow: auto;
  }
  &__item {
    width: 35vw;
    padding: 8px 16px;
    transition: all 0.15s ease-in-out;
    display: flex;
    gap: 10px;
    border-bottom: 1px solid #f2f2f2;
    &:hover {
      background-color: #f2f2f2;
      cursor: pointer;
    }
  }
  &__more {
    margin: 15px auto 0 auto;
    padding: 5px 15px;
    border-radius: 3px;
    width: fit-content;
    &:hover {
      background-color: #f2f2f2;
      cursor: pointer;
    }
  }
  &__time {
    font-weight: 700;
    color: #989898;
  }
  &__corp {
    font-weight: 700;
  }
  &__desc {
    font-size: 0.9em;
  }
  &__empty {
    width: 100%;
    font-weight: 400;
    min-width: 160px;
    padding: 0 10px;
    color: inherit;
    text-align: center;
  }
  &__closeBtn {
    position: absolute;
    top: 5px;
    right: 10px;
    display: none;
  }
}
@media (max-width: 1024px) {
  .notificationsModal {
    padding: 10px 0;
    &__ico {
      width: 20px;
      height: 20px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media (max-width: 480px) {
  .notificationsModal {
    padding: 40px 0 10px 0;
    &__list {
      min-width: auto;
      width: 90vw;
      height: 80vh;
      height: 80dvh;
    }
    &__item {
      width: auto;
    }
    &__closeBtn {
      display: block;
    }
  }
}
</style>
