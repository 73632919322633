<template>
  <div class="createCompany">
    <div class="createCompany-wrap">
      <div class="createCompany__content">
        <div class="createCompany__content-exit">
          <button class="createCompany-btn" @click="closeModal">Закрыть</button>
        </div>
        <div class="createCompany__content-create">
          <label class="createCompany__label" for="createCompany-create"
            >Имя компании</label
          >
          <input
            class="createCompany__input"
            type="text"
            id="createCompany-create"
            v-model="valueInput"
          />
        </div>
        <div
          class="createCompany__content-edit"
          v-if="$store.state.companies.editCompanyName"
        >
          <button class="createCompany-btn" @click="editCompany">
            Изменить
          </button>
        </div>
        <div class="createCompany__content-added" v-else>
          <button class="createCompany-btn" @click="addedCompany">
            Создать
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
export default {
  name: "createCompany",
  setup() {
    const store = useStore();

    const closeModal = () => {
      store.commit("setAddedCompany", false);
      // если открывали мод окно для редактирования(смена на создание)
      if (store.state.companies.editCompanyName) {
        store.commit("setEditCompany", null);
      }
    };

    const valueInput = ref("");
    const addedCompany = () => {
      store.dispatch("createCompany", { name: valueInput.value });
    };

    const editCompany = () => {
      let data = {
        id: store.state.companies.editCompanyName.id,
        company: { name: valueInput.value },
      };
      store.dispatch("editCompany", data);
    };

    onMounted(() => {
      // подставляем данные (если окно для редактирования)
      if (store.state.companies.editCompanyName)
        valueInput.value = store.state.companies.editCompanyName.name;
    });

    return {
      closeModal,
      addedCompany,
      editCompany,
      valueInput,
    };
  },
};
</script>

<style lang="scss" scoped>
.createCompany {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  transition: opacity 200ms ease-in;
  margin: 0;
  padding: 0;
  pointer-events: auto;
  opacity: 1;
  &-wrap {
    min-width: 650px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    padding: 30px;
    max-height: 50vh;
    max-height: 50dvh;
    overflow-y: auto;
  }
  &__content {
    width: 100%;
    &-create {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }
    &-added,
    &-exit,
    &-edit {
      text-align: end;
    }
    &-exit {
      margin-bottom: 15px;
    }
  }
  &__label {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
      Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    line-height: 1.5;
    color: #9c9c9c;
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 15px;
  }
  &__input {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    padding-right: 20px;
    outline: none;
    &:focus {
      border-color: #e31e24;
      box-shadow: 0 0 0 0.25rem rgb(227 30 36 / 25%);
    }
  }
  &-btn {
    color: #fff;
    background-color: #e31e24;
    border-color: #e31e24;
    border: 1px solid transparent;
    padding: 9px 12px;
    text-align: center;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover {
      color: #fff;
      background-color: #b02a37;
      border-color: #b02a37;
    }
  }
}
@media (max-width: 1024px) {
  .createCompany {
    &-wrap {
      padding: 20px;
      min-width: 50vw;
    }
    &-btn {
      padding: 5px 8px;
    }
    &__content {
      &-exit {
        margin-bottom: 0;
      }
      &-create {
        margin-bottom: 20px;
      }
    }
  }
}
@media (max-width: 768px) {
  .createCompany {
    &-wrap {
      min-width: 70vw;
    }
  }
}
@media (max-width: 480px) {
  .createCompany {
    &-wrap {
      min-width: auto;
      width: 98vw;
    }
    &__input,
    &-btn {
      padding: 8px;
    }
  }
}
</style>
