import { useApi } from "@/composition/useApi.js";
export default {
  state: {
    allAccounts: [], // все счета
    tariffTypes: null, // типы для тарифа
    isAddedAccount: false, // модалка добавления счета
    isReplenishBalance: false, // модалка пополнения счета
    currentAccountForReplenish: null, // текущий счет для пополнения
    editAccount: null, // текущий редактируемый счет
    spinnerIsShow: false,
  },
  mutations: {
    setAllAccounts(state, data) {
      state.spinnerIsShow = false;
      state.allAccounts = data;
    },
    setTariffTypes(state, data) {
      state.tariffTypes = data;
    },
    setAddedAccount(state, bool) {
      state.isAddedAccount = bool;
    },
    //создание счета
    setCreateAccount(state, data) {
      state.allAccounts.push(data);
    },
    // редактирование и обновление
    setEditAccount(state, account) {
      state.editAccount = account;
    },
    setUpdateAccount(state, account) {
      state.allAccounts = state.allAccounts.map(item => {
        if (item.id == account.id) item = account;
        return item;
      });
    },
    //удаление счетов
    setDeleteAccount(state, data) {
      state.allAccounts = state.allAccounts.filter(
        account => account.id != data.id,
      );
    },
    //поиск и обновление
    searchAccount(state, dataInput) {
      document
        .querySelectorAll(
          ".accounts .allAccounts__tbody .allAccounts__tbody-name",
        )
        .forEach(colName => {
          if (colName.innerText.toLowerCase().includes(dataInput.toLowerCase()))
            colName.parentNode.style.display = "table-row";
          else colName.parentNode.style.display = "none";
        });
    },
    searchAccountUpdate() {
      document
        .querySelectorAll(".accounts .allAccounts__tbody tr")
        .forEach(row => {
          row.style.display = "table-row";
        });
    },
    // пополнение баланса
    setCurrentAccountForReplenish(state, data) {
      state.currentAccountForReplenish = data;
    },
    setReplenishBalanceModal(state, bool) {
      state.isReplenishBalance = bool;
    },
  },
  actions: {
    getAllAccounts({ commit, state }) {
      const { api } = useApi();
      state.spinnerIsShow = true;
      return api
        .get("/billing/invoices")
        .then(r => {
          if (r.status == 200) {
            commit("setAllAccounts", r.data);
          }
        })
        .catch(e => {
          console.log("error: ", e);
          state.spinnerIsShow = false;
        });
    },
    getTariffTypes({ commit }) {
      const { api } = useApi();
      return api
        .get("/objects/types")
        .then(r => {
          if (r.status == 200) {
            commit("setTariffTypes", r.data);
          }
        })
        .catch(e => {
          console.log("error: ", e);
        });
    },
    createAccount({ commit }, body) {
      const { api } = useApi();
      return api
        .post("/billing/invoices", body)
        .then(r => {
          if (r.status == 201) {
            commit("setCreateAccount", r.data);
            //закрытие окна
            commit("setAddedAccount", false);
          }
        })
        .catch(e => {
          console.log("error: ", e);
        });
    },
    editAccount({ commit }, data) {
      const { api } = useApi();
      return api
        .put(`/billing/invoices/${data.id}`, data.body)
        .then(r => {
          if (r.status == 200) {
            commit("setUpdateAccount", r.data);
            //переключение мод окна на "создание"
            commit("setEditAccount", null);
            //закрытие окна
            commit("setAddedAccount", false);
          }
        })
        .catch(e => {
          console.log("error: ", e);
        });
    },
    deleteAccount({ commit }, payload) {
      const { api } = useApi();
      return api
        .delete(`/billing/invoices/${payload.id}`)
        .then(r => {
          if (r.status == 204) {
            commit("setDeleteAccount", payload);
          }
        })
        .catch(e => {
          console.log("error: ", e);
        });
    },
    topUpAccount({ commit }, data) {
      // пополнение баланса
      const { api } = useApi();
      return api
        .patch(`/billing/invoices/${data.id}/balance`, data.body)
        .then(r => {
          if (r.status == 200) {
            commit("setUpdateAccount", r.data);
            //закрытие окна
            commit("setReplenishBalanceModal", false);
          }
        })
        .catch(e => {
          console.log("error: ", e);
        });
    },
  },
};
