<template>
  <div class="users">
    <div class="users__buttons">
      <button class="users__btn" @click="openModal">
        Добавить пользователя
      </button>
    </div>
    <div class="users__wrapper">
      <h1 class="users__title">Все пользователи</h1>
      <div class="users__search">
        <input
          class="search"
          type="text"
          placeholder="Поиск"
          v-model="searchUsers"
          @input="searchInput"
        />
        <div class="users__search-icon">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.24235 6C1.24235 3.37242 3.37242 1.24235 6 1.24235C8.62758 1.24235 10.7577 3.37242 10.7577 6C10.7577 8.62758 8.62758 10.7577 6 10.7577C3.37242 10.7577 1.24235 8.62758 1.24235 6ZM6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C7.43148 12 8.74588 11.4987 9.77721 10.6621L12.9319 13.8167C13.1762 14.0611 13.5724 14.0611 13.8167 13.8167C14.0611 13.5724 14.0611 13.1762 13.8167 12.9319L10.6621 9.77721C11.4987 8.74588 12 7.43148 12 6C12 2.68629 9.31371 0 6 0Z"
              fill="#AEB3CB"
            />
          </svg>
        </div>
      </div>
      <div class="users__list">
        <div class="users__list-users">
          <div
            class="users__list-table"
            v-if="$store.state.users.allUsers.length"
          >
            <AllUsers />
          </div>
          <div
            class="users__list-spinner"
            v-else-if="$store.state.users.spinnerIsShow"
          >
            <SpinnerComponent />
          </div>
          <div class="users__list-noUsers" v-else>Пользователей нет</div>
        </div>
      </div>
      <div class="users__createUser" v-if="$store.state.users.isAddedUser">
        <ModalCreateUser />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import ModalCreateUser from "@/components/modal/ModalCreateUser.vue";
import SpinnerComponent from "@/components/UI/SpinnerComponent.vue";
import AllUsers from "@/components/adminPageComponents/AllUsers.vue";
export default {
  name: "usersMain",
  components: {
    AllUsers,
    SpinnerComponent,
    ModalCreateUser,
  },
  setup() {
    const store = useStore();

    const searchUsers = ref("");
    const searchInput = () => {
      if (searchUsers.value.length)
        store.commit("searchUser", searchUsers.value);
      else store.commit("searchUserUpdate");
    };
    const openModal = () => {
      // сброс поиска
      store.commit("searchUserUpdate");
      searchUsers.value = "";
      //сначала получаем список компаний
      store.dispatch("getAllCompanies").then(() => {
        store.commit("setAddedUser", true);
      });
    };
    onMounted(() => {
      // обновляем пользователей
      store.dispatch("getAllUsers");
    });

    return {
      searchUsers,
      searchInput,
      openModal,
    };
  },
};
</script>

<style></style>
