import { useApi } from "@/composition/useApi.js";
export default {
  state: {
    reportsData: null, // данные для таблицы отчета
  },
  mutations: {
    setReportsData(state, data) {
      data.forEach(itm => {
        itm.statusEvents.forEach(statusEvt => (statusEvt.visibleGroup = false));
      });
      state.reportsData = data;
    },
    setReportGroupVisibility(state, payload) {
      const reportEvt = state.reportsData.find(
        itm => itm.id == payload.reportId,
      ).statusEvents[payload.statusEvtIdx];
      reportEvt.visibleGroup = !reportEvt.visibleGroup;
    },
  },
  actions: {
    getReports({ commit }, payload) {
      // данные о пользователе
      const { api } = useApi();
      return api
        .get(
          `/objects/reports/status?from=${payload.from}&to=${payload.to}&companyIds=${payload.ids}`,
        )
        .then(r => {
          if (r.status == 200) commit("setReportsData", r.data);
        });
    },
  },
};
