<template>
  <span
    class="invisibleCover"
    @click.stop="$emit('invisibleCoverClickHandler')"
  />
</template>

<script>
export default {
  emits: ["invisibleCoverClickHandler"],
};
</script>

<style lang="scss" scoped>
.invisibleCover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  z-index: 9;
  overflow: hidden;
}
</style>
