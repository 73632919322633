import { useApi } from "@/composition/useApi.js";
export default {
  state: {
    dataGroup: null, // текущая группа камер
    cameraStreamData: null, // данные для модалки из одной камеры
    singleCameraIsShow: false, //модалка для показа одной камеры
    allCamerasIsShow: false, //модалка для показа всех камер
    multipleToFullscreen: false, // переменная отвечает за закрытие Multiple с последующим открытием Fullscreen

    playlistIsShow: false, // модалка видео-плейлист
    currentCameraData: null, // данные о текщей камере
    currentPlaylist: null, // текущий плейлист
    videoJSPlayerIsShow: false, // плеер для проигрывания записей
    currentVideo: null, // текущее видео
  },
  mutations: {
    setDataGroup(state, data) {
      state.dataGroup = data;
    },
    setCameraStreamData(state, object) {
      state.cameraStreamData = object;
    },
    setVideoStreamData(state, data) {
      state.VideoStreamData = data;
    },
    // скрытие/показ всех камер
    toggleVisibilityAllCameras(state, bool) {
      state.allCamerasIsShow = bool;
    },
    // скрытие/показ одной камеры
    toggleVisibilitySingleCamera(state, bool) {
      state.singleCameraIsShow = bool;
    },
    setMultipleToFullscreen(state, bool) {
      state.multipleToFullscreen = bool;
    },
    // скрытие/показ плейлиста с видео
    toggleVisibilityPlaylist(state, bool) {
      state.playlistIsShow = bool;
    },
    // скрытие/показ проигрывателя записей
    toggleVisibilityPlayer(state, bool) {
      state.videoJSPlayerIsShow = bool;
    },
    setCurrentCameraData(state, payload) {
      state.currentCameraData = payload;
    },
    setCurrentVideo(state, payload) {
      state.currentVideo = payload;
    },
    setCurrentPlaylist(state, payload) {
      state.currentPlaylist = payload;
    },
  },
  actions: {
    getVideoStreamData({ commit }, cameraId) {
      const { api } = useApi();
      return api.get(`/rtsp-stream/${cameraId}`).then(r => {
        if (r.status == 200) commit("setVideoStreamData", r.data);
      });
    },
    getPlaylist({ commit, state }, payload) {
      const { api } = useApi();
      return api
        .get(
          `/archive/${state.currentCameraData.id}?from=${payload.from}&to=${payload.to}`,
        )
        .then(r => {
          if (r.status == 200) commit("setCurrentPlaylist", r.data);
        });
    },
  },
};
