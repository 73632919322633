import axios from "axios";
import config from "/config.json";
import router from "@/router";
import store from "@/store";

function redirectToLogin(message) {
  // показ alert
  store.dispatch("setTimeoutAlert", { text: message, type: "error" });
  // Неавторизованный пользователь - редирект на "/login"
  router.push("/login");
}

let refreshPromise = null; // переменная отвечает за существование промиса на refreshToken

export function useApi() {
  // получение токена и запись в localStorage
  const getToken = data => {
    return api.post("/auth/login", data).then(r => {
      localStorage.setItem("accessToken", r.data.accessToken);
      localStorage.setItem("refreshToken", r.data.refreshToken);
    });
  };
  // обновление токена и запись в localStorage
  const refreshToken = () => {
    if (refreshPromise) return refreshPromise;
    localStorage.removeItem("accessToken");
    return (refreshPromise = api
      .get("/auth/refresh")
      .then(r => {
        localStorage.setItem("accessToken", r.data.accessToken);
        localStorage.setItem("refreshToken", r.data.refreshToken);
      })
      .catch(r => {
        redirectToLogin(r.response.data.message);
      })
      .finally(() => {
        refreshPromise = null;
      }));
  };
  // получение токена и запись в localStorage
  const logout = () => {
    return api.post("/auth/logout").then(r => {
      if (r.status == 204) {
        router.push("/login");
      }
    });
  };
  const _axios = (method, route, data, options = {}) => {
    return new Promise((resolve, reject) => {
      let token =
        localStorage.getItem("accessToken") ||
        localStorage.getItem("refreshToken");
      try {
        axios({
          method,
          url: `${config.API_URL}${route}`,
          data,
          withCredentials: true,
          headers: token ? { Authorization: `Bearer ${token}` } : {},
          ...options,
        })
          .then(resolve)
          .catch(r => {
            if (!r.response) {
              switch (r.code) {
                case "ERR_NETWORK":
                  store.dispatch("setTimeoutAlert", {
                    text: r.message,
                    type: "warning",
                  });
                  break;
                default:
                  reject(r);
                  break;
              }
            } else {
              switch (r.response.status) {
                case 401:
                  if (route == "/auth/refresh") localStorage.clear();
                  if (localStorage.getItem("refreshToken")) {
                    return refreshToken().then(() => {
                      _axios(method, route, data, options).then(resolve);
                    });
                  }
                  redirectToLogin(r.response.data.message);
                  break;
                default:
                  // показ alert
                  store.dispatch("setTimeoutAlert", {
                    text: r.response.data.message,
                    type: "error",
                  });
                  reject(r);
                  break;
              }
            }
          });
      } catch (error) {
        console.log(error);
      }
    });
  };

  //Factory
  const api = {
    get: (route, options) => _axios("get", route, null, options),
    post: (route, data, options) => _axios("post", route, data, options),
    put: (route, data, options) => _axios("put", route, data, options),
    delete: (route, data, options) => _axios("delete", route, data, options),
    patch: (route, data, options) => _axios("patch", route, data, options),
  };

  return {
    api,
    getToken,
    logout,
  };
}
