import { useApi } from "@/composition/useApi.js";
export default {
  state: {
    allGroups: [],
    allGroupsCompany: null, // Все группы камер у компаний
    allObjectsGroup: null, // Все камеры у компании
    companyForGroup: null,
    groupForObject: null,

    editObject: null,
    editGroup: null,

    errorText: null,

    spinnerGroup: true, // спиннер списка групп у камер
    isVisibleObjectsGroup: true,
    isVisibleCreateObject: false, // на фалс

    isVisibleAddedGroup: false,
    selectTypes: [], // select выбора типа обьекта
  },
  getters: {
    getFilteredAllGroupsCompany: state => groupsSearch => {
      if (groupsSearch) {
        const filteredArr = state.allGroupsCompany.filter(elem => {
          const condition1 = elem.name
            .toLowerCase()
            .includes(groupsSearch.toLowerCase());
          const filteredObj = elem.objects?.filter(obj =>
            obj.name.toLowerCase().includes(groupsSearch.toLowerCase()),
          );
          const condition2 = filteredObj?.length;
          return condition1 || condition2;
        });
        return filteredArr;
      } else {
        return state.allGroupsCompany;
      }
    },
    getFilteredAllObjectsGroup: (state, getters) => groupsSearch => {
      if (groupsSearch) {
        const filteredCompanyIds = getters
          .getFilteredAllGroupsCompany(groupsSearch)
          .map(itm => itm.id);
        const filteredArr = state.allObjectsGroup.filter(
          elem =>
            elem.name.toLowerCase().includes(groupsSearch.toLowerCase()) &&
            filteredCompanyIds.includes(elem.groupId),
        );
        if (filteredArr.length) return filteredArr;
        else return state.allObjectsGroup;
      } else {
        return state.allObjectsGroup;
      }
    },
  },
  mutations: {
    // данные для отрисовки
    setAllGroups(state, data) {
      state.allGroups = data;
    },
    setAllGroupsCompany(state, data) {
      state.allGroupsCompany = data;
    },
    setCompanyForGroup(state, data) {
      state.companyForGroup = data;
    },
    setGroupForObject(state, data) {
      state.groupForObject = data;
    },
    setObjectsGroup(state, data) {
      state.allObjectsGroup = data;
      this.commit("setCamerasGroupStatus");
      if (!data?.length) return;
      state.allGroupsCompany.forEach(groupCompany => {
        groupCompany.objects = data.filter(
          object => object.groupId == groupCompany.id,
        );
      });
    },
    setEditObject(state, data) {
      state.editObject = data;
    },
    setEditingGroup(state, data) {
      state.editGroup = data;
    },
    // по сокету получ. статус и изменяем у камеры
    setObjectStatus(state, data) {
      data.groups.forEach(currentGroup => {
        currentGroup.trackObjects.forEach(trackObj => {
          const currentObject = state.allObjectsGroup.find(
            obj => obj.id === trackObj.id,
          );
          currentObject.isOnline = trackObj.isOnline;
          currentObject.status = trackObj.isOnline ? "ONLINE" : "OFFLINE";
        });
      });
    },
    // устанавливаем статус группе камер
    setCamerasGroupStatus(state) {
      state.allGroupsCompany.forEach(group => {
        const groupCameras = state.allObjectsGroup.filter(
          obj => obj.groupId === group.id,
        );
        if (!groupCameras.length) {
          group.groupStatus = "EMPTY";
        } else {
          const onlineObjectsLength = groupCameras.filter(
            itm => itm.isOnline,
          ).length;
          if (onlineObjectsLength === groupCameras.length) {
            group.groupStatus = "ONLINE";
          } else if (onlineObjectsLength === 0) {
            group.groupStatus = "OFFLINE";
          } else {
            group.groupStatus = "PROBLEM";
          }
        }
      });
    },
    // редактирование объекта
    setEditingObject(state, object) {
      state.allObjectsGroup = state.allObjectsGroup.map(item => {
        if (item.id == object.id) {
          item = object;
        }
        return item;
      });
    },
    // добавление группы
    setCreateGroup(state, body) {
      // создание массива при добавлении самой первой компании
      if (!state.allGroupsCompany) {
        state.allGroupsCompany = [];
        state.allGroupsCompany.push(body);
      } else {
        state.allGroupsCompany.push(body);
      }
    },
    // добавление объекта в группу
    setCreateObject(state, body) {
      // создание массива при добавлении самой первой компании
      if (!state.allObjectsGroup) {
        state.allObjectsGroup = [];
        state.allObjectsGroup.push(body);
      } else {
        state.allObjectsGroup.push(body);
      }
    },
    // удаление группы
    setDeleteGroup(state, data) {
      state.allGroupsCompany = state.allGroupsCompany.filter(
        group => group.id != data.id,
      );
      //отключение спинера если нет групп
      if (!state.allGroupsCompany.length) state.allGroupsCompany = null;
    },
    //удаление объекта
    setDeleteObject(state, data) {
      state.allObjectsGroup = state.allObjectsGroup.filter(
        object => object.id != data.id,
      );
    },

    setVisibleObjectsGroup(state, bool) {
      state.isVisibleObjectsGroup = bool;
    },

    setVisibleAddedGroup(state, bool) {
      state.isVisibleAddedGroup = bool;
    },
    setVisibleCreateObject(state, bool) {
      state.isVisibleCreateObject = bool;
    },
    setSinnerGroup(state, bool) {
      state.spinnerGroup = bool;
    },
    setErrorText(state, text) {
      state.errorText = text;
      setTimeout(() => {
        state.errorText = null;
      }, 3500);
    },
    setAttachmentsOnEditingObject(state, attachment) {
      // удаление названия вложения у редактируемой камеры
      const editingObj = state.allObjectsGroup.find(
        obj => obj.id == state.editObject.id,
      );
      editingObj.attachments = editingObj.attachments.filter(
        item => item != attachment,
      );
    },
    addAttachmentsOnEditingObject(state, attachment) {
      // добавление названия вложения у редактируемой камеры
      const editingObj = state.allObjectsGroup.find(
        obj => obj.id == state.editObject.id,
      );
      editingObj.attachments.push(attachment);
    },
    setSelectTypes(state, data) {
      state.selectTypes = data;
    },
  },
  actions: {
    getSelectTypes({ commit }) {
      // получаем все типы для селекта типов объекта
      const { api } = useApi();
      return api.get("/objects/types").then(r => {
        if (r.status == 200) commit("setSelectTypes", r.data);
      });
    },
    createGroup({ commit }, body) {
      const { api } = useApi();
      return api
        .post("/groups", body)
        .then(r => {
          if (r.status == 201) {
            commit("setCreateGroup", r.data);
            //закрытие окна
            commit("setVisibleAddedGroup", false);
            return r;
          }
        })
        .catch(r => {
          if (r.response.statusText == "Bad Request") {
            commit(
              "setErrorText",
              Array.isArray(r.response.data.message)
                ? r.response.data.message.join(", ")
                : r.response.data.message,
            );
          }
        });
    },
    createObject({ commit }, body) {
      const { api } = useApi();
      return api
        .post("/objects", body)
        .then(r => {
          if (r.status == 201) {
            commit("setCreateObject", r.data);
            //закрытие окна
            commit("setVisibleCreateObject", false);
            //очищение активного объекта ( т к отрисовка "создания" основана на отсутствии данных об объекте)
            commit("setEditObject", null);
          }
        })
        .catch(r => {
          if (r.response.statusText == "Bad Request") {
            commit(
              "setErrorText",
              Array.isArray(r.response.data.message)
                ? r.response.data.message.join(", ")
                : r.response.data.message,
            );
          }
        });
    },
    editObject({ state, commit }, body) {
      const { api } = useApi();
      return api
        .put(`/objects/${state.editObject.id}`, body)
        .then(r => {
          if (r.status == 200) {
            commit("setEditingObject", r.data);
            //закрытие окна
            commit("setVisibleCreateObject", false);
            //очищение активного объекта ( т к отрисовка "создания" основана на отсутствии данных об объекте)
            commit("setEditObject", null);
          }
        })
        .catch(r => {
          if (r.response.statusText == "Bad Request") {
            commit(
              "setErrorText",
              Array.isArray(r.response.data.message)
                ? r.response.data.message.join(", ")
                : r.response.data.message,
            );
          }
        });
    },
    editGroup({ state, commit }, data) {
      const { api } = useApi();
      return api
        .put(`/groups/${state.editGroup.id}`, data)
        .then(r => {
          if (r.status == 200) {
            commit("setEditingObject", r.data);
            //закрытие окна
            commit("setVisibleAddedGroup", false);
            return r;
          }
        })
        .catch(r => {
          if (r.response.statusText == "Bad Request") {
            commit(
              "setErrorText",
              Array.isArray(r.response.data.message)
                ? r.response.data.message.join(", ")
                : r.response.data.message,
            );
          }
        });
    },
    deleteGroups({ commit }, data) {
      const { api } = useApi();
      return api.delete(`/groups/${data.id}`).then(r => {
        if (r.status == 200) {
          commit("setDeleteGroup", data);
        }
      });
    },

    deleteObject({ commit }, data) {
      const { api } = useApi();
      return api.delete(`/objects/${data.id}`).then(r => {
        if (r.status == 200) {
          commit("setDeleteObject", data);
        }
      });
    },
    getAllGroupsCompany({ commit }, companyId) {
      // получаем сразу все группы компаний и все объекты камер
      const { api } = useApi();
      commit("setSinnerGroup", true);
      Promise.all([
        api.get(`/groups/company/${companyId}`),
        api.get(`/objects/company/${companyId}`),
      ])
        .then(r => {
          if (r[0].status == 200 && r[1].status == 200) {
            commit("setAllGroupsCompany", r[0].data);
            commit("setObjectsGroup", r[1].data);
            commit("setSinnerGroup", false);
          }
        })
        .catch(() => {
          commit("setSinnerGroup", false);
          commit("setErrorText", "Что-то пошло не так...");
        });
    },
  },
};
