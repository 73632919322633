<template>
  <div
    class="push"
    :class="{
      'push-danger': pushData.type == 'danger',
      'push-info': pushData.type == 'info',
      'push-warning': pushData.type == 'warning',
      'push-success': pushData.type == 'success',
    }"
    v-if="pushData.text"
  >
    <button type="button" class="close" @click="closePush">×</button>
    <div class="icon" v-if="pushData.type == 'danger'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-x-circle"
        viewBox="0 0 16 16"
      >
        <path
          d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
        />
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
        />
      </svg>
    </div>
    <div class="icon" v-else>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-check-circle"
        viewBox="0 0 16 16"
      >
        <path
          d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
        />
        <path
          d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
        />
      </svg>
    </div>
    <div class="push__text">
      <strong>Внимание!</strong>
    </div>
    <div class="push__text" v-if="typeof pushData.text === 'string'">
      {{ pushData.text }}
    </div>
    <div class="push__block" v-if="pushData.text.constructor === Array">
      <div
        class="push__text"
        v-for="(message, idx) in pushData.text"
        :key="idx"
      >
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
  props: {
    pushData: {
      type: Object,
    },
  },
  setup(props) {
    const closePush = () => {
      store.commit("removePush", props.pushData.id);
    };
    return { closePush };
  },
};
</script>

<style lang="scss" scoped>
.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  &:hover,
  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
  }
}
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.push {
  padding: 15px 15px 15px 61px;
  max-width: 35vw;
  border: 1px solid transparent;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.11);
  background-image: linear-gradient(to bottom, #ffffff, #f9f9f9);
  border-top-color: #d8d8d8;
  border-bottom-color: #bdbdbd;
  border-left-color: #cacaca;
  border-right-color: #cacaca;
  color: #404040;
  position: relative;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  z-index: 3;
  width: 100%;
  max-width: 30vw;
  .push__text {
    padding-right: 20px;
  }
  &.push-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
  }
  &.push-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
  }
  &.push-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
  }
  &.push-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
  }
  &.push-danger .icon,
  &.push-danger .icon:after {
    border-color: #ca452e;
    background: #da4932;
  }

  &.push-info .icon,
  &.push-info .icon:after {
    border-color: #3a8ace;
    background: #4d90fd;
  }

  &.push-warning .icon,
  &.push-warning .icon:after {
    border-color: #d68000;
    background: #fc9700;
  }

  &.push-success .icon,
  &.push-success .icon:after {
    border-color: #54a754;
    background: #60c060;
  }
}
.push .icon {
  width: 45px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #bdbdbd;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    display: block;
    content: "";
    width: 10px;
    height: 10px;
    border: 1px solid #bdbdbd;
    position: absolute;
    border-left: 0;
    border-bottom: 0;
    top: 50%;
    right: -6px;
    margin-top: -5px;
    background: #fff;
  }
}
@media (max-width: 768px) {
  .push {
    max-width: 40vw;
  }
}
@media (max-width: 480px) {
  .push {
    max-width: 85vw;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  button.close {
    font-size: 25px;
  }
}
</style>
