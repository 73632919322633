import { useApi } from "@/composition/useApi.js";
export default {
  state: {
    allCompanies: [],
    editCompanyName: null,
    spinnerIsShow: false,
    isVisibleCompanies: true,
    isAddedCompany: false,
    createCompany: null,
  },
  mutations: {
    setAllCompanies(state, data) {
      // делаем изначально все чекбоксы неактивными
      data.forEach(company => (company.checked = false));
      state.allCompanies = data;
    },
    //создание компании
    setCreateCompany(state, data) {
      state.allCompanies.push(data);
    },
    // редактирование и обновление
    setEditCompany(state, company) {
      state.editCompanyName = company;
    },
    setUpdateCompany(state, company) {
      state.allCompanies = state.allCompanies.map(item => {
        if (item.id == company.id) item = company;
        return item;
      });
    },
    //удаление компании
    setDeleteCompany(state, data) {
      state.allCompanies = state.allCompanies.filter(
        company => company.id != data.id,
      );

      //проверка на удаление всех компаний для вывода блока "компаний нет"
      if (!state.allCompanies.length) {
        state.isVisibleCompanies = false;
      }
    },
    //поиск и обновление
    searchCompany(state, dataInput) {
      document
        .querySelectorAll(
          ".companies .allCompanies__tbody .allCompanies__tbody-name",
        )
        .forEach(colName => {
          if (colName.innerText.toLowerCase().includes(dataInput.toLowerCase()))
            colName.parentNode.style.display = "table-row";
          else colName.parentNode.style.display = "none";
        });
    },
    searchCompanyUpdate() {
      document
        .querySelectorAll(".companies .allCompanies__tbody tr")
        .forEach(row => {
          row.style.display = "table-row";
        });
    },
    //остальные данные
    setVisibleCompanies(state, bool) {
      state.isVisibleCompanies = bool;
    },
    setAddedCompany(state, bool) {
      state.isAddedCompany = bool;
    },
    //(Админка> Добавление пользователя) устанавливаем флажки на всех чекбоксах
    setSelectAllCheckboxes(state, bool) {
      state.allCompanies.forEach(company => (company.checked = bool));
    },
    setToggleCheckbox(state, payload) {
      // выбор и снятие чекбокса
      state.allCompanies.forEach(company => {
        if (company.id == payload.id) {
          company.checked = payload.isChecked;
        }
      });
    },
    setSomeCheckboxes(state, ids) {
      // выбор нескольких чекбоксов
      state.allCompanies.forEach(
        company => (company.checked = ids.includes(company.id)),
      );
    },
  },
  actions: {
    getAllCompanies({ commit, state }) {
      const { api } = useApi();
      state.spinnerIsShow = true;
      return api
        .get("/companies")
        .then(r => {
          if (r.status == 200) {
            state.spinnerIsShow = false;
            commit("setAllCompanies", r.data);
            if (r.data && r.data.length) commit("setVisibleCompanies", true);
            else commit("setVisibleCompanies", false);
          }
        })
        .catch(e => {
          state.spinnerIsShow = false;
          console.log("error: ", e);
        });
    },
    createCompany({ commit }, body) {
      const { api } = useApi();
      return api
        .post("/companies", body)
        .then(r => {
          if (r.status == 201) {
            commit("setCreateCompany", r.data);
            //закрытие окна
            commit("setAddedCompany", false);
            //если компаний не было -  убираем блок 'компаний нет'
            commit("setVisibleCompanies", true);
          }
        })
        .catch(e => {
          console.log("error: ", e);
        });
    },
    editCompany({ commit }, data) {
      const { api } = useApi();
      return api
        .put(`/companies/${data.id}`, data.company)
        .then(r => {
          if (r.status == 200) {
            commit("setUpdateCompany", r.data);
            //переключение мод окна на "создание"
            commit("setEditCompany", null);
            //закрытие окна
            commit("setAddedCompany", false);
          }
        })
        .catch(e => {
          console.log("error: ", e);
        });
    },
    deleteCompany({ commit }, data) {
      const { api } = useApi();
      return api
        .delete(`/companies/${data.id}`)
        .then(r => {
          if (r.status == 200) {
            commit("setDeleteCompany", data);
          }
        })
        .catch(e => {
          console.log("error: ", e);
        });
    },
  },
};
