import { useApi } from "@/composition/useApi.js";
export default {
  state: {
    userData: null, // храним данные пользователя после его успешной авторизации
    errorText: null, // текст ошибки модального окна
    alertData: {
      // текст и тип алерта
      type: null,
      text: null,
    },
    pushData: [], // текст и тип push уведомлений
    pushCounter: 0, // количество новых уведомлений
    showMoreBtn: true, // кнопка показать больше
    allNotifications: [], // данные модалки с уведомлениями
    allNotificationsIsShow: false, // состояние модалки с уведомлениями
  },
  getters: {
    accessIsAllowed(state) {
      // Разрешен ли доступ. Возвращает bool
      return ["ADMIN", "OPERATOR"].includes(state.userData?.role);
    },
  },
  mutations: {
    setUserData(state, data) {
      state.userData = data;
    },
    setUserErrorText(state, text) {
      // показ модалки
      state.errorText = text;
    },
    setAlertText(state, payload) {
      // постоянный показ алерта, либо его скрытие
      state.alertData = payload;
    },
    setPush(state, payload) {
      // показ PUSH
      state.pushData.push(payload);
    },
    removePush(state, pushId) {
      // скрытие PUSH
      state.pushData = state.pushData.filter(item => item.id !== pushId);
    },
    setPushCounter(state) {
      // добавляем кол-во нового пуш
      state.pushCounter += 1;
    },
    resetPushCounter(state) {
      // обнуляем счетчик
      state.pushCounter = 0;
      // кнопку возвращаем в исходное
      state.showMoreBtn = true;
    },
    setNotificationsData(state, data) {
      if (data.length < 10) state.showMoreBtn = false;
      state.allNotifications.push(...data);
    },
    removeNotificationsData(state) {
      state.allNotifications = [];
    },
    setVisibilityNotifications(state, bool) {
      state.allNotificationsIsShow = bool;
    },
  },
  actions: {
    getUser({ commit }) {
      // данные о пользователе
      const { api } = useApi();
      return api
        .get("/auth/user")
        .then(r => {
          if (r.status == 200) commit("setUserData", r.data);
        })
        .catch(r => commit("setUserErrorText", r.data));
    },
    setTimeoutAlert({ commit }, payload) {
      // показ Алерта на 7 секунд
      commit("setAlertText", payload);
      setTimeout(() => {
        commit("setAlertText", { text: null, type: null });
      }, 7000);
    },
    setPushNotification({ commit, state }, payload) {
      // показ Push уведомления - асинх. операция
      commit("setPush", payload);
      setTimeout(() => {
        if (state.pushData.length) {
          commit("removePush", payload.id);
        }
      }, 10000);
    },
    getNotifications({ commit }, payload) {
      // данные о пользователе
      const { api } = useApi();
      return api
        .get(`/notifications?offset=${payload.offset}&limit=${payload.limit}`)
        .then(r => {
          if (r.status == 200) {
            commit("resetPushCounter");
            commit("setNotificationsData", r.data);
          }
        });
    },
  },
};
